import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";

import ClockLoader from "react-spinners/ClockLoader";
import { Flex } from "@aws-amplify/ui-react";
import { SearchField } from "@aws-amplify/ui-react";
import { getSummaryFromURL } from "./graphql/queries";

export default function SearchForm() {
  const [response, setResponse] = useState({ result: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");

  function GetSummaryFromURL(query, firstCall = false) {
    setIsLoading(true);
    setQuery(query)

    // Change URI in the browser
    window.history.pushState({}, "", '/?url=' + encodeURIComponent(query));

    let res = "Error: can't fetch content. It is probably your fault.";
    API.graphql(
      graphqlOperation(getSummaryFromURL, { q: query })
    ).then((response) => {
      console.log(response);
      res = response["data"]["getSummaryFromURL"];
      if(res.summary == '<pending>' && !firstCall) {
        setTimeout(() => {
          console.log("Polling lambda function...");
          GetSummaryFromURL(query);
        }, 2000);
      } else {
        setIsLoading(false);
        setResponse(res);
      }
   }).catch((e) => {
      console.log("Error while calling lambda function:");
      console.error(e);
      setIsLoading(false);
   })
  }

  useEffect(() => {
    // Get URL from the browser
    const urlParams = new URLSearchParams(window.location.search);
    const url = urlParams.get('url');
    if(url != null && !isLoading) {
      console.log('Calling GetSummaryFromURL');
      GetSummaryFromURL(url, true);
    }
  }, []);


  return (
    <div>
      <Flex direction="column" alignItems="center" justifyContent="center" wrap="wrap">
        <SearchField
            label="Search"
            placeholder="URL to a news article, blog post, or youtube video"
            style={{width: '28rem'}}
            value={query}
            onChange={((query) => setQuery(query.value))}
            onSubmit={(query) => GetSummaryFromURL(query)}/>
         <ClockLoader color="#2596be" loading={isLoading} />
      {!isLoading && response.summary != undefined && 
            <div style={{textAlign: "left", width: '100%', wordWrap: 'break-word', fontFamily: 'Arial, Helvetica, sans-serif'}}>
                {response.summary.split('\n').map(line => 
                    <div style={{margin: "0.5rem"}}>{line}</div>
                )}
            </div>
      } 
       </Flex>
    </div>
  );
}
