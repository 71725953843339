import './App.css';
import "@aws-amplify/ui-react/styles.css";

import { AmplifyProvider, Card, Grid } from "@aws-amplify/ui-react";
import React, { useEffect } from "react";

import { Amplify } from "aws-amplify";
import SearchForm from "./SearchForm";
import awsconfig from "./aws-exports";
import barsLogo from "./logo.png";

function App() {
  return(
    <AmplifyProvider>
      <div className="App">
        <Grid
          columnGap="0.5rem"
          rowGap="0.5rem"
          templateColumns="1fr 1fr 1fr"
          templateRows="5rem 15rem 3fr 15rem"
        >
          <Card columnStart="1" columnEnd="-1"></Card>
          <Card columnStart="1" columnEnd="-1">
            <img src={barsLogo} width="300px" height="auto" />
          </Card>
          <Card columnStart="2" columnEnd="3">
            <SearchForm style={{ fontFamily: "Roboto, sans-serif" }} />
          </Card>
          <Card columnStart="1" columnEnd="-1"></Card>
        </Grid>
      </div>
    </AmplifyProvider>
  );
}

export default App;
